<template>
    <section id="registerr">
        <h1 class="mb-8">Register</h1>
        <v-form ref="form" @submit.prevent="validate">
            <v-text-field v-model="form.name"
                          label="Name"
                          :rules="[v => !!v || 'Name is required']"
                          outlined
                          dense
            ></v-text-field>
            <v-text-field v-model="form.last_name"
                          label="Last Name"
                          :rules="[v => !!v || 'Last Name is required']"
                          outlined
                          dense
            ></v-text-field>
            <v-text-field v-model="form.email"
                          label="Email"
                          :rules="emailRules"
                          outlined
                          dense
            >
            </v-text-field>
            <v-text-field v-model="form.password"
                          label="Password"
                          :rules="[v => !!v || 'Password is required']"
                          type="password"
                          outlined
                          dense
            >
            </v-text-field>
            <div class="d-flex">
                <v-btn :loading="loading" class="mr-auto" :disabled="loading" @click="$router.push({name: 'login'})">
                    Back
                </v-btn>
                <v-btn color="primary" :loading="loading" :disabled="loading" @click="login">
                    submit
                </v-btn>
            </div>
        </v-form>
    </section>
</template>


<script>
    import {mapGetters} from "vuex";
    import auth from "../../../../api/modules/auth";
    import {EventBus} from "../../../../bootstrap/EventBus";

    export default {
        name: "Login",
        data: () => ({
            form: {
                email: null,
                password: null,
            },
            errors: null,
            loading: false,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
        }),
        computed: {
            ...mapGetters('auth', ['check'])
        },
        created: function () {
            if (this.check) {
                this.$router.push({name: 'users'})
            }
        },
        methods: {
            login() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    auth.register(this.form).then(res => {
                        this.$store.dispatch("auth/saveAuthUser", res.data).then(() => {
                            this.$router.push({name: 'dashboard'});
                            this.loading = false;
                        });
                    }).catch(e => {
                        this.loading = false;
                        EventBus.$emit('submit-error', e);
                    });
                }
            },
            validate() {
                this.$refs.form.validate()
            },
        }
    }
</script>
